import React, { createContext, useContext, useEffect, useState } from 'react';
import { FlagResponse } from '@getbread/feature-flag-sdk';
import { getFeatureFlags } from '../lib/handlers/get-feature-flags';
import { logger } from '../lib/logger';
import { FCWithChildren } from '../lib/types';
import { setAdditionalContext } from '../lib/analytics';
import { useAppConfig } from './AppConfigContext';
import { useDeviceID, useExperienceKeys } from './XPropsContext';
import { Loader } from './LoadingManager';

export enum IntroPageABPhaseOneResult {
  Control = 'control',
  SideBySide = 'side_by_side',
  Streamlined = 'streamlined',
  Off = 'off',
}

export interface FeatureFlags {
  enableIDVerification: boolean;
  enableSplitPayAuthEnhancement: boolean;
  disableColoradoAPR: boolean;
  introPageABPhaseOne: IntroPageABPhaseOneResult;
  enableAlloyJourney: boolean;
}

const featureFlagDefaults: FeatureFlags = {
  enableIDVerification: false,
  enableSplitPayAuthEnhancement: false,
  disableColoradoAPR: false,
  introPageABPhaseOne: IntroPageABPhaseOneResult.Off,
  enableAlloyJourney: false,
};

const FeatureFlagsContext = createContext<FeatureFlags>(featureFlagDefaults);

interface FeatureFlagsProviderProps {
  removeLoader: (loader: Loader) => void;
}

export const FeatureFlagsProvider: FCWithChildren<FeatureFlagsProviderProps> =
  ({ removeLoader, children }) => {
    const [featureFlags, setFeatureFlags] =
      useState<FeatureFlags>(featureFlagDefaults);
    const deviceID = useDeviceID();
    const { merchantID, programID } = useExperienceKeys();
    const { apiUrl, tenantID, featureFlagsEnabled } = useAppConfig();

    /**
     * Searches through the flag response to look for any flag with the
     * prefix `enable-alloy` due to volatile naming of the shared flag.
     *
     * Since we are limited to changes of an existing feature flag it must
     * be created with a new name in order to be updated. This is due to the
     * flag name being it's ID instead of a UUID.
     *
     * All feature flags relating to the Alloy journey will always be
     * prefixed with `enable-alloy` and be tagged for checkout experience
     */
    const isAlloyEnabled = (flags: Record<string, FlagResponse>): boolean => {
      const alloyFlag = Object.entries(flags).find(([key]) =>
        key.includes('enable-alloy')
      );

      return alloyFlag?.[1]?.result === 'true';
    };

    useEffect(() => {
      const fetchFeatureFlags = async () => {
        let flagResults: FeatureFlags = featureFlagDefaults;
        let response: Record<string, FlagResponse> | null = null;

        try {
          response = await getFeatureFlags(
            apiUrl,
            merchantID,
            tenantID,
            deviceID,
            programID
          );

          const enableIDVerification =
            response['id_verification']?.result === 'true';
          const enableSplitPayAuthEnhancement =
            response['splitpay_payment_auth_enhancement']?.result === 'true';
          const disableColoradoAPR = response['ColoradoAPR']?.result === 'true';
          const introPageABPhaseOne =
            (response['intro_page_ab_phase_one']
              ?.result as IntroPageABPhaseOneResult) ||
            IntroPageABPhaseOneResult.Off;
          const enableAlloyJourney = isAlloyEnabled(response);

          flagResults = {
            enableIDVerification,
            enableSplitPayAuthEnhancement,
            disableColoradoAPR,
            introPageABPhaseOne,
            enableAlloyJourney,
          };

          setFeatureFlags(flagResults);
        } catch (err) {
          logger.error({ error: err }, 'Error accessing feature flags!');
        } finally {
          setAdditionalContext({
            feature_flags: JSON.stringify(flagResults).replace(/{|}|"/g, ''),
          });
          removeLoader('FEATURE_FLAGS');
        }
      };

      if (featureFlagsEnabled) {
        fetchFeatureFlags();
      }
    }, []);

    return (
      <FeatureFlagsContext.Provider value={featureFlags}>
        {children}
      </FeatureFlagsContext.Provider>
    );
  };

export const useFeatureFlags = (): FeatureFlags => {
  return useContext(FeatureFlagsContext);
};
